<template>
  <div>
    <!--  Snackbar  -->
    <div class="text-center">
      <v-snackbar
          multi-line
          width="500px"
          centered
          shaped
          top
          v-model="snackbarStatus"
          :timeout="snackbarTimeout"
          :color="snackbarColor"
      >
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbarStatus = false"
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <h3>مستندات Api</h3>

    <v-tabs background-color="transparent" right color="#161853">
      <v-tab>سرویس ویدیو</v-tab>
      <v-tab>سرویس تصویر</v-tab>

      <v-tab-item :eager="true" v-if="videos.length">
        <dev-doc :items="videos"></dev-doc>
      </v-tab-item>
      <v-tab-item v-if="images.length">
        <dev-doc :items="images"></dev-doc>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import DevDoc from "../components/Documents/DevDoc";

export default {
  name: "ApiDocument",
  components: {DevDoc},
  data() {
    return {
      videos: {},
      images: {},
    }
  },

  methods: {
    getVideos() {
      window.axios.get('user/documents/dev?type=video').then((res) => {
        this.videos = res.data.data
      })
    },
    getImages() {
      window.axios.get('user/documents/dev?type=image').then((res) => {
        this.images = res.data.data
      })
    }
  },

  mounted() {
    this.getVideos()
    this.getImages()
  }
}
</script>

<style scoped>
#custom-tab-items {
  background-color: transparent !important;
}
</style>