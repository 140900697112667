<template>
  <div>
    <v-list>
      <v-row>
        <v-col xl="2" lg="3" md="4" sm="12" cols="12">
          <v-list-item :class="tab === item.id ? 'v-list-item--active' : ''" @click="tab = item.id" v-for="(item, index) in items" :key="'tab' +index" link>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-col>
        <v-col xl="10" lg="9" md="8" sm="12" cols="12">
          <v-list-item-content v-for="(item, index) in items" :key="'item' + index" :style="{ display: tab === item.id ? '' : 'none' }">
            <request :method="item.http_method"
                     :url="item.url"
                     :input="item.input"
                     :output="item.output"
                     :description="item.description">
            </request>
          </v-list-item-content>
        </v-col>
      </v-row>
    </v-list>
  </div>
</template>

<script>
import Request from "./Request";

export default {
  name: "DevDoc",
  components: {Request},
  props: ['items'],
  data() {
    return {
      tab: null,
    }
  },
  watch: {
    items: function (newItems) {
      this.tab = newItems[0].id
    }
  },
  mounted() {
    if (this.items && this.items.length) {
      this.tab = this.items[0].id
    }
  }
}
</script>

<style scoped>

</style>