<template>
  <div style="padding: 20px; width: 100%">
    <v-row style="direction: ltr; margin: 15px; padding: 5px; border: 1px solid #161853; border-radius: 10px">
      <v-col cols="12" sm="12" md="3" lg="2" xl="1" style="text-align: center!important; background-color: #161853; border-radius: 20px">
        <span style="color: white; font-size: 18px">{{ method }}</span>
      </v-col>
      <v-col cols="12" sm="12" md="9" lg="10" xl="11" style="text-align: left!important;">
        <span style="font-family: Arial">{{ baseURL + 'api/' + url }}</span>
      </v-col>
    </v-row>

    <v-row style="margin: 15px!important; direction: ltr!important;">
      <v-col cols="12" style="background-color: #dfdada; border-radius: 10px">
        <div style="width: 100%">
          <h3 style="width: fit-content; float: left">Input:</h3>
          <v-btn x-small fab color="#EC255A" @click="CopyToClipboard(input)"><v-icon color="white">mdi-content-copy</v-icon></v-btn>
        </div>
        <pre style="direction: ltr; text-align: left!important; word-wrap: break-word; white-space: pre-wrap;">{{ input }}</pre>
      </v-col>
    </v-row>

    <v-row style="margin: 15px!important; direction: ltr!important;">
      <v-col cols="12" style="background-color: #dfdada; border-radius: 10px">
        <div style="width: 100%">
          <h3 style="width: fit-content; float: left">Output:</h3>
          <v-btn x-small fab color="#EC255A" @click="CopyToClipboard(output)"><v-icon color="white">mdi-content-copy</v-icon></v-btn>
        </div>
        <pre style="direction: ltr; text-align: left!important; word-wrap: break-word; white-space: pre-wrap;">{{ output }}</pre>
      </v-col>
    </v-row>

    <v-row>
      <v-col style="padding: 30px">
        <p style="text-align: justify!important;" v-html="description"></p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Request",

  props: ['method', 'url', 'input', 'output', 'description'],

  methods: {
    CopyToClipboard(text) {
      navigator.clipboard.writeText(text);
    }
  }
}
</script>

<style scoped>

</style>